import create from "zustand";
import { immer, persist } from "state/middlewares";
import shallow from "lib/shallow";
import Api from "lib/api";

const formsStore = create(
  persist(
    "forms",
    immer((set, get) => ({
      errors: {},
      // Saves the form values in a persisted store
      // so that it can be retrieved later
      setForm: (name, formValues) => {
        set((state) => {
          state[name] = formValues;
        });
      },
      setErrors: (name, errors) => {
        if (!errors) {
          set((state) => {
            delete state.errors[name];
          });
          return;
        }
        // Errors, coming from react-hook-form
        // Have a ref element that doesn't play well with
        // sessionStorage
        // Also, errors on Array fields are in arrays that don't
        // have all their indexes, so we "inline" those like:
        // diplome[1]title
        //
        // Let's just hope we don't have nested array fields…
        set((state) => {
          state.errors[name] = Object.entries(errors).reduce(
            (acc = {}, [fieldName, error]) => {
              // Handle array fields error
              if (Array.isArray(error)) {
                error.forEach((error, i) => {
                  Object.entries(error).forEach(([fname, error]) => {
                    const name = `${fieldName}[${i}].${fname}`;

                    acc[name] = {
                      type: error.type,
                      message: error.message,
                    };
                  });

                  return acc;
                });
              }
              // Simple fields
              else {
                acc[fieldName] = {
                  type: error.type,
                  message: error.message,
                };
              }

              return acc;
            },
            {}
          );
        });
      },
      // Gets form values from the store for the form
      // with the given name
      getForm: (name) => {
        const state = get();

        if (!Object.hasOwnProperty.call(state, name)) {
          return {};
        }

        const form = state[name];

        Object.keys(form).forEach((key) => {
          if (form[key] === null) {
            try {
              delete form[key];
            } catch (error) {
              console.warn(error);
            }
          }
        });

        return state[name];
      },
      getErrors: () => {
        return get().errors;
      },
      countForms: () => {
        const state = get();

        return Object.entries(state)
          .filter(([_key, value]) => typeof value !== "function")
          .reduce((acc) => acc + 1, 0);
      },
      saveFormToDb: async (token, candidatureId) => {
        try {
          const state = get();

          const { results } = await Api.saveFormToDb(token, {
            candidatureId,
            step1: state["etape-1"],
            step2: state["etape-2"],
            step3: state["etape-3"],
            step4: state["etape-4"],
            step5: state["etape-5"],
            step6: state["etape-6"],
            // step7: state["etape-7"],
            step8: state["etape-8"],
            step9: state["etape-9"],
            step10: state["etape-10"],
            step11: state["etape-11"],
          });

          if (results) {
            if (results.stepsToUpdate) {
              Object.keys(results.stepsToUpdate).forEach((step) =>
                set((state) => {
                  state[step] = results.stepsToUpdate[step];
                })
              );
            }
          }
        } catch (error) {
          set((state) => {
            state.error = error;
          });
        }
      },
      associateFilesToEntity: ({ files, name, candidature }) => {
        const state = get();
        if (["pieceIdentite", "cv"].includes(name)) {
          files = files
            // .filter((f) => typeof id === "string") // don't upload files with ids
            .map((f) => {
              f.field = `${name}Id`;
              f.value = state["etape-1"].id;

              return f;
            });
        }
        if (name.includes("politiqueConflitsInterets")) {
          files = files
            // .filter((f) => typeof id === "string") // don't upload files with ids
            .map((f) => {
              f.field = `${name}Id`;
              f.value = candidature.id;
              return f;
            });
        }
        if (name.includes("refusEtValidation")) {
          const index = name.match(new RegExp("(?<=\\[).+?(?=\\])"));
          const rvEntity = state["etape-2"].refusEtValidation[index];
          files = files.map((f) => {
            f.field = `justificatifId`;
            f.value = rvEntity.id;
            return f;
          });
        }
        return files;
      },
      uploadFile: async (token, name, files, candidature) => {
        const newFiles = get().associateFilesToEntity({
          files,
          name,
          token,
          candidature,
        });

        const formData = new FormData();
        Object.keys(newFiles).forEach((key) => {
          const file = newFiles[key];
          formData.append(
            `${file.field}-${file.value}`,
            new Blob([file], { type: file.type }),
            file.name || "file"
          );
        });

        const { results } = await Api.uploadFile(token, formData);
        if (results) {
          return results;
        } else {
          set((state) => {
            state.error = "Le fichier n’a pas pu être enregistré";
          });
        }
      },
      removeFile: async (token, name, fileId) => {
        const { results } = await Api.removeFile(token, { name, fileId });
        if (results) {
          return results;
        } else {
          set((state) => {
            state.error = "Le fichier n’a pas pu être enregistré";
          });
        }
      },
      listEntites: async (token) => {
        const state = get();

        if (state.entites) return state.entites;

        const { results } = await Api.listEntites({ token });
        if (results) {
          set((state) => {
            state.entites = results;
          });

          return results;
        } else {
          set((state) => {
            state.error = "Impossible d’obtenir la liste des entités";
          });
        }
      },
      computeDedicatedDays: async (token, id, params) => {
        const { results } = await Api.computeDedicatedDays({
          token,
          id,
          body: params,
        });

        if (results) {
          return results.dedicatedDays;
        } else {
          set((state) => {
            state.error =
              "Impossible de calculer le temps consacré à la fonction";
          });
        }
      },
      finishCandidature: async (token, id) => {
        try {
          await Api.finishCandidature(token, id);
        } catch (error) {
          set((state) => {
            state.error =
              "Votre formulaire n’a pu être enregistré comme finalisé";
          });
        }
      },
    }))
  )
);

export const useForms = (slices) => shallow(formsStore, slices);
export const formsApi = formsStore;
