export default {
  id: "1",
  title: "Votre identité",
  next: "2",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [{ name: "id", type: "hidden" }],
        [
          {
            name: "civilite",
            label: "Civilite",
            type: "select",
            values: [
              { value: "monsieur", label: "M." },
              { value: "madame", label: "Mme." },
              { value: "autre", label: "Autre" },
            ],
          },
          {
            name: "nom",
            label: "Nom de famille",
            autoFocus: true,
            options: {
              required: "Ce champ est requis",
            },
          },
          { name: "nomUsage", label: "Nom d’usage" },
        ],
        [
          {
            name: "prenom",
            label: "Prénom",
            options: { required: "Ce champ est requis" },
          },
          {
            name: "prenomAutres",
            label: "Autre(s) prénom(s)",
            helpText: "Séparés par un virgule",
          },
        ],
        [
          {
            name: "email",
            label: "Mail professionel",
            options: {
              required: "Ce champ est requis",
              pattern: {
                value: "/([A-Z-]+, )*/i",
                message: "Cette adresse n’est pas valide",
              },
            },
          },
        ],
        [
          {
            name: "dateNaissance",
            label: "Date de naissance",
            type: "date",
            placeholder: "dd-mm-yyyy",

            options: {
              required: "Ce champ est requis",
              max: "today",
            },
          },
          { type: "blank" },
        ],
        [
          {
            name: "aUtiliseAutresPrenoms",
            label: "Avez-vous eu ou utilisé d’autres noms par le passé&nbsp;?",
            type: "yes-no",
            options: {
              required: "Ce champ est requis",
              no: "Non",
              yes: "Oui",
            },
            whenYes: [
              [
                {
                  name: "anciensNoms",
                  type: "array",
                  fields: [
                    [{ name: "id", type: "hidden" }],
                    [
                      {
                        name: "civilite",
                        label: "Civilite",
                        type: "select",
                        values: [
                          { value: "monsieur", label: "M." },
                          { value: "madame", label: "Mme." },
                          { value: "autre", label: "Mx." },
                        ],
                      },
                      {
                        name: "nom",
                        label: "Nom de famille",
                        autoFocus: true,
                        options: {
                          required: "Ce champ est requis",
                        },
                      },
                      { name: "nomUsage", label: "Nom d’usage" },
                    ],
                    [
                      {
                        name: "prenom",
                        label: "Prénom",
                        options: { required: "Ce champ est requis" },
                      },
                      {
                        name: "prenomsAutres",
                        label: "Autre(s) prénom(s)",
                        helpText: "Séparés par un virgule",
                      },
                    ],
                    [
                      {
                        name: "dateChangement",
                        type: "date",
                        label: "Date du changement",
                        options: { required: "Ce champ est requis" },
                      },
                    ],
                  ],
                },
              ],
            ],
          },
        ],
      ],
    },
    {
      title: "Lieu de naissance",
      rows: [
        [
          {
            name: "paysNaissance",
            label: "Pays",
            options: { required: "Ce champ est requis" },
          },
          {
            name: "villeNaissance",
            label: "Commune",
          },
        ],
        [
          {
            name: "codePostalNaissance",
            label: "Code postal",
          },
          {
            name: "nationalites",
            label: "Nationalité(s)",
            type: "select",
            options: { isMulti: true },
            values: [
              { value: "AFG", label: "Afghane" },
              { value: "ALB", label: "Albanaise" },
              { value: "DZA", label: "Algérienne" },
              { value: "DEU", label: "Allemande" },
              { value: "USA", label: "Americaine" },
              { value: "AND", label: "Andorrane" },
              { value: "AGO", label: "Angolaise" },
              { value: "ATG", label: "Antiguaise-et-Barbudienne" },
              { value: "ARG", label: "Argentine" },
              { value: "ARM", label: "Armenienne" },
              { value: "AUS", label: "Australienne" },
              { value: "AUT", label: "Autrichienne" },
              { value: "AZE", label: "Azerbaïdjanaise" },
              { value: "BHS", label: "Bahamienne" },
              { value: "BHR", label: "Bahreinienne" },
              { value: "BGD", label: "Bangladaise" },
              { value: "BRB", label: "Barbadienne" },
              { value: "BEL", label: "Belge" },
              { value: "BLZ", label: "Belizienne" },
              { value: "BEN", label: "Béninoise" },
              { value: "BTN", label: "Bhoutanaise" },
              { value: "BLR", label: "Biélorusse" },
              { value: "MMR", label: "Birmane" },
              { value: "GNB", label: "Bissau-Guinéenne" },
              { value: "BOL", label: "Bolivienne" },
              { value: "BIH", label: "Bosnienne" },
              { value: "BWA", label: "Botswanaise" },
              { value: "BRA", label: "Brésilienne" },
              { value: "GBR", label: "Britannique" },
              { value: "BRN", label: "Brunéienne" },
              { value: "BGR", label: "Bulgare" },
              { value: "BFA", label: "Burkinabée" },
              { value: "BDI", label: "Burundaise" },
              { value: "KHM", label: "Cambodgienne" },
              { value: "CMR", label: "Camerounaise" },
              { value: "CAN", label: "Canadienne" },
              { value: "CPV", label: "Cap-verdienne" },
              { value: "CAF", label: "Centrafricaine" },
              { value: "CHL", label: "Chilienne" },
              { value: "CHN", label: "Chinoise" },
              { value: "CYP", label: "Chypriote" },
              { value: "COL", label: "Colombienne" },
              { value: "COM", label: "Comorienne" },
              { value: "COG", label: "Congolaise" },
              { value: "COD", label: "Congolaise" },
              { value: "COK", label: "Cookienne" },
              { value: "CRI", label: "Costaricaine" },
              { value: "HRV", label: "Croate" },
              { value: "CUB", label: "Cubaine" },
              { value: "DNK", label: "Danoise" },
              { value: "DJI", label: "Djiboutienne" },
              { value: "DOM", label: "Dominicaine" },
              { value: "DMA", label: "Dominiquaise" },
              { value: "EGY", label: "Égyptienne" },
              { value: "ARE", label: "Émirienne" },
              { value: "GNQ", label: "Équato-guineenne" },
              { value: "ECU", label: "Équatorienne" },
              { value: "ERI", label: "Érythréenne" },
              { value: "ESP", label: "Espagnole" },
              { value: "TLS", label: "Est-timoraise" },
              { value: "EST", label: "Estonienne" },
              { value: "ETH", label: "Éthiopienne" },
              { value: "FJI", label: "Fidjienne" },
              { value: "FIN", label: "Finlandaise" },
              { value: "FRA", label: "Française" },
              { value: "GAB", label: "Gabonaise" },
              { value: "GMB", label: "Gambienne" },
              { value: "GEO", label: "Georgienne" },
              { value: "GHA", label: "Ghanéenne" },
              { value: "GRD", label: "Grenadienne" },
              { value: "GTM", label: "Guatémaltèque" },
              { value: "GIN", label: "Guinéenne" },
              { value: "GUY", label: "Guyanienne" },
              { value: "HTI", label: "Haïtienne" },
              { value: "GRC", label: "Hellénique" },
              { value: "HND", label: "Hondurienne" },
              { value: "HUN", label: "Hongroise" },
              { value: "IND", label: "Indienne" },
              { value: "IDN", label: "Indonésienne" },
              { value: "IRQ", label: "Irakienne" },
              { value: "IRN", label: "Iranienne" },
              { value: "IRL", label: "Irlandaise" },
              { value: "ISL", label: "Islandaise" },
              { value: "ISR", label: "Israélienne" },
              { value: "ITA", label: "Italienne" },
              { value: "CIV", label: "Ivoirienne" },
              { value: "JAM", label: "Jamaïcaine" },
              { value: "JPN", label: "Japonaise" },
              { value: "JOR", label: "Jordanienne" },
              { value: "KAZ", label: "Kazakhstanaise" },
              { value: "KEN", label: "Kenyane" },
              { value: "KGZ", label: "Kirghize" },
              { value: "KIR", label: "Kiribatienne" },
              { value: "KNA", label: "Kittitienne et Névicienne" },
              { value: "KWT", label: "Koweïtienne" },
              { value: "LAO", label: "Laotienne" },
              { value: "LSO", label: "Lesothane" },
              { value: "LVA", label: "Lettone" },
              { value: "LBN", label: "Libanaise" },
              { value: "LBR", label: "Libérienne" },
              { value: "LBY", label: "Libyenne" },
              { value: "LIE", label: "Liechtensteinoise" },
              { value: "LTU", label: "Lituanienne" },
              { value: "LUX", label: "Luxembourgeoise" },
              { value: "MKD", label: "Macédonienne" },
              { value: "MYS", label: "Malaisienne" },
              { value: "MWI", label: "Malawienne" },
              { value: "MDV", label: "Maldivienne" },
              { value: "MDG", label: "Malgache" },
              { value: "MLI", label: "Maliennes" },
              { value: "MLT", label: "Maltaise" },
              { value: "MAR", label: "Marocaine" },
              { value: "MHL", label: "Marshallaise" },
              { value: "MUS", label: "Mauricienne" },
              { value: "MRT", label: "Mauritanienne" },
              { value: "MEX", label: "Mexicaine" },
              { value: "FSM", label: "Micronésienne" },
              { value: "MDA", label: "Moldave" },
              { value: "MCO", label: "Monegasque" },
              { value: "MNG", label: "Mongole" },
              { value: "MNE", label: "Monténégrine" },
              { value: "MOZ", label: "Mozambicaine" },
              { value: "NAM", label: "Namibienne" },
              { value: "NRU", label: "Nauruane" },
              { value: "NLD", label: "Néerlandaise" },
              { value: "NZL", label: "Néo-Zélandaise" },
              { value: "NPL", label: "Népalaise" },
              { value: "NIC", label: "Nicaraguayenne" },
              { value: "NGA", label: "Nigériane" },
              { value: "NER", label: "Nigérienne" },
              { value: "NIU", label: "Niuéenne" },
              { value: "PRK", label: "Nord-coréenne" },
              { value: "NOR", label: "Norvégienne" },
              { value: "OMN", label: "Omanaise" },
              { value: "UGA", label: "Ougandaise" },
              { value: "UZB", label: "Ouzbéke" },
              { value: "PAK", label: "Pakistanaise" },
              { value: "PLW", label: "Palaosienne" },
              { value: "PSE", label: "Palestinienne" },
              { value: "PAN", label: "Panaméenne" },
              { value: "PNG", label: "Papouane-Néo-Guinéenne" },
              { value: "PRY", label: "Paraguayenne" },
              { value: "PER", label: "Péruvienne" },
              { value: "PHL", label: "Philippine" },
              { value: "POL", label: "Polonaise" },
              { value: "PRT", label: "Portugaise" },
              { value: "QAT", label: "Qatarienne" },
              { value: "ROU", label: "Roumaine" },
              { value: "RUS", label: "Russe" },
              { value: "RWA", label: "Rwandaise" },
              { value: "LCA", label: "Saint-Lucienne" },
              { value: "SMR", label: "Saint-Marinaise" },
              { value: "VCT", label: "Saint-Vincentaise et Grenadine" },
              { value: "SLB", label: "Salomonaise" },
              { value: "SLV", label: "Salvadorienne" },
              { value: "WSM", label: "Samoane" },
              { value: "STP", label: "Santoméenne" },
              { value: "SAU", label: "Saoudienne" },
              { value: "SEN", label: "Sénégalaise" },
              { value: "SRB", label: "Serbe" },
              { value: "SYC", label: "Seychelloise" },
              { value: "SLE", label: "Sierra-Léonaise" },
              { value: "SGP", label: "Singapourienne" },
              { value: "SVK", label: "Slovaque" },
              { value: "SVN", label: "Slovène" },
              { value: "SOM", label: "Somalienne" },
              { value: "SDN", label: "Soudanaise" },
              { value: "LKA", label: "Sri-Lankaise" },
              { value: "ZAF", label: "Sud-Africaine" },
              { value: "KOR", label: "Sud-Coréenne" },
              { value: "SSD", label: "Sud-Soudanaise" },
              { value: "SWE", label: "Suédoise" },
              { value: "CHE", label: "Suisse" },
              { value: "SUR", label: "Surinamaise" },
              { value: "SWZ", label: "Swazie" },
              { value: "SYR", label: "Syrienne" },
              { value: "TJK", label: "Tadjike" },
              { value: "TZA", label: "Tanzanienne" },
              { value: "TCD", label: "Tchadienne" },
              { value: "CZE", label: "Tchèque" },
              { value: "THA", label: "Thaïlandaise" },
              { value: "TGO", label: "Togolaise" },
              { value: "TON", label: "Tonguienne" },
              { value: "TTO", label: "Trinidadienne" },
              { value: "TUN", label: "Tunisienne" },
              { value: "TKM", label: "Turkmène" },
              { value: "TUR", label: "Turque" },
              { value: "TUV", label: "Tuvaluane" },
              { value: "UKR", label: "Ukrainienne" },
              { value: "URY", label: "Uruguayenne" },
              { value: "VUT", label: "Vanuatuane" },
              { value: "VAT", label: "Vaticane" },
              { value: "VEN", label: "Vénézuélienne" },
              { value: "VNM", label: "Vietnamienne" },
              { value: "YEM", label: "Yéménite" },
              { value: "ZMB", label: "Zambienne" },
              { value: "ZWE", label: "Zimbabwéenne" },
            ],
          },
        ],
        [
          {
            label:
              "Numéro de carte d’identité/de passeport en cours de validité",
            name: "numeroId",
          },
        ],
        [
          {
            label: "Pays d’émission de la carte d’identité/du passeport",
            name: "paysId",
          },
        ],
      ],
    },
    {
      title: "Lieu de résidence actuel",
      rows: [
        [
          {
            type: "hidden",
            name: "residenceActuelle-id",
          },
        ],
        [
          { label: "Adresse", name: "residenceActuelle-adresse" },
          { label: "Ville", name: "residenceActuelle-ville" },
        ],
        [
          { label: "Pays", name: "residenceActuelle-pays" },
          {
            label: "Date d’arrivée à cette adresse",
            name: "residenceActuelle-dateArrivee",
            type: "date",
            options: {
              max: "today",
            },
          },
        ],
        [
          {
            label:
              "Avez-vous vécu dans un autre pays pendant les cinq dernières années&nbsp;?",
            name: "vecuAutresPays",
            type: "yes-no",
            whenYes: [
              [
                {
                  label:
                    "Pays de résidence aux cours des cinq dernières années",
                  helpText: "Tous les pays, et les périodes concernées",
                  type: "text",
                },
              ],
            ],
          },
        ],
      ],
    },
    {
      title:
        "Lieu de résidence principal (si différent du lieu de résidence actuel)",
      rows: [
        [
          {
            type: "hidden",
            name: "residencePrincipal-id",
          },
        ],
        [
          { label: "Adresse", name: "residencePrincipal-adresse" },
          { label: "Ville", name: "residencePrincipal-ville" },
        ],
        [
          { label: "Pays", name: "residencePrincipal-pays" },
          {
            label: "Date d’arrivée à cette adresse",
            name: "residencePrincipal-dateArrivee",
            type: "date",
            options: {
              max: "today",
            },
          },
        ],
      ],
    },
  ],
};
