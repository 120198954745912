import _ from "lodash";

export function fadeIn(id, spd) {
  if (id.style.opacity === "") {
    id.style.opacity = 1;

    id.style.filter = "alpha(opacity=" + 100 + ")";
  }

  if (id.style.opacity < 1) {
    var opac = 0; // initial opacity

    var cycle = setInterval(increaseOpacity, spd);

    function increaseOpacity() {
      opac += 0.01;

      if (opac >= 1) {
        id.style.opacity = 1;

        opac = 1;

        clearInterval(cycle);
      }

      id.style.opacity = opac;

      id.style.filter = "alpha(opacity=" + opac * 100 + ")"; // IE fallback
    }
  } else {
    clearInterval(cycle);
  }
}

export function fadeOut(id, spd) {
  if (id.style.opacity === "") {
    id.style.opacity = 1;

    id.style.filter = "alpha(opacity=" + 100 + ")";
  }

  if (id.style.opacity > 0) {
    var opac = 1;

    var cycle = setInterval(decreaseOpacity, spd);

    function decreaseOpacity() {
      opac -= 0.01;

      if (opac <= 0) {
        id.style.opacity = 0;

        opac = 0;

        clearInterval(cycle);
      }

      id.style.opacity = opac;

      id.style.filter = "alpha(opacity=" + opac * 100 + ")";
    }
  } else {
    clearInterval(cycle);
  }
}

export function getClosest(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}

export function flattenErrors(errors) {
  return Object.entries(errors).reduce((acc = {}, [fieldName, error]) => {
    // Handle array fields error
    if (Array.isArray(error)) {
      error.forEach((error, i) => {
        Object.entries(error).forEach(([fname, error]) => {
          const name = `${fieldName}[${i}].${fname}`;

          acc[name] = {
            type: error.type,
            message: error.message,
          };
        });

        return acc;
      });
    }
    // Simple fields
    else {
      acc[fieldName] = {
        type: error.type,
        message: error.message,
      };
    }

    return acc;
  }, {});
}

export function deflateError(errors) {
  let result = {};

  Object.entries(errors).forEach(([fieldName, error]) => {
    _.set(result, fieldName, error);
  });

  return result;
}

export function targetsIMAS(_, candidature) {
  return candidature.portalTarget === "imas";
}

export function targetsACPR(_, candidature) {
  return candidature.portalTarget === "acpr";
}
