import React from "react";

import { Controller } from "react-hook-form";

import DatePicker, { registerLocale } from "react-datepicker";
import { format, isAfter, isBefore } from "date-fns";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";
registerLocale("fr", fr);

export default function ControlledDatePicker({
  className,
  name,
  label,
  placeholder,
  error,
  helpText,
  required,
  form,
  options = {},
}) {
  let classes = className || "flex flex-col";

  if (required || options?.required) {
    classes += " required";
  }

  const clearable = !(required || options?.required);

  return (
    <Controller
      control={form.control}
      name={name}
      rules={{
        validate: {
          required: (value) => {
            if (options.required) {
              if (!!value) {
                return true;
              }

              return options.required;
            }
          },
          min: (value) => {
            if (!value) {
              return true;
            }

            const date = new Date(value);

            let min =
              options.min?.value ?? (options.min || "1901-01-01T00:00:00.000Z");
            min = min === "today" ? new Date() : new Date(min);
            const message =
              options.min?.message ||
              `La date doit être postérieure au ${format(min, "dd/MM/yyyy")}`;

            if (isAfter(date, min)) {
              return true;
            }

            const result = !isAfter(date, min) && message;
            return result || undefined;
          },
          max: (value) => {
            if (!value) {
              return true;
            }

            const date = new Date(value);

            let max =
              options.max?.value ?? options.max ?? "2999-12-31T12:59:59.999Z";
            max = max === "today" ? new Date() : new Date(max);
            const message =
              options.max?.message ||
              `La date doit être antérieure au ${format(max, "dd/MM/yyyy")}`;

            if (isBefore(date, max)) {
              return true;
            }

            const result = !isBefore(date, max) && message;
            return result || undefined;
          },
        },
      }}
      render={({ onChange, onBlur, value, name }) => {
        let selected = null;

        if (value) {
          try {
            selected = new Date(value);

            if (isNaN(selected)) {
              selected = new Date();
            }
          } catch (e) {
            // ignore
          }
        }

        return (
          <label className={classes}>
            <span>{label}</span>
            <DatePicker
              locale={fr}
              placeholderText={placeholder || label}
              name={name}
              selected={selected}
              onChange={(date) => {
                if (date) {
                  onChange(date.toISOString());
                } else {
                  onChange(null);
                }
              }}
              onFocus={() => {
                form.clearErrors(name);
              }}
              onBlur={(...args) => {
                form.trigger(name);
                onBlur(...args);
              }}
              firstDayOfWeek={1}
              isClearable={clearable}
              dateFormat="dd/MM/yyyy"
              minDate={
                options?.min === "today"
                  ? new Date()
                  : new Date(options?.min || "1901-01-01")
              }
              maxDate={
                options?.max === "today"
                  ? new Date()
                  : new Date(options?.max || "2999-12-31T12:59:59.999Z")
              }
            />
            {error ? (
              <span className="help-text error">{error}</span>
            ) : (
              <span className="help-text">{helpText}</span>
            )}
          </label>
        );
      }}
    />
  );
}
