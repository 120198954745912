const acprForm = {
  id: "3",
  title: "Votre honorabilité",
  next: "4",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          {
            name: "condamnation",
            type: "yes-no",
            label:
              "Avez-vous personnellement ou la personne morale en lien avec vos responsabilités actuelles ou anciennes déjà fait l'objet d'une procédure, y compris une procédure clôturée ou en appel, dans le cadre d'une procédure pénale, civile ou administrative (ayant eu une incidence sur votre honorabilité ou une incidence significative sur votre situation financière)&#x202F;?",
            whenYes: [
              [
                {
                  name: "condamnationHelper",
                  type: "subtext",
                  content:
                    "Veuillez apporter des précisions, en particulier concernant la nature des investigations ou des chefs d'accusation, le temps écoulé depuis le début de la procédure ou de la condamnation, la peine prononcée, le comportement de la personne nommée (renouvelée) depuis les faits en cause, tout éclairage d'ordre professionnel apporté par la personne nommée (renouvelée), toute autre circonstance atténuante et tout autre facteur aggravant",
                },
              ],
              [
                {
                  name: "condamnationText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            name: "procedureEnCours",
            type: "yes-no",
            label:
              "Faites-vous personnellement ou la personne morale en lien avec vos responsabilités actuelles ou anciennes l'objet d'une procédure pénale ou d'une procédure civile ou administrative en cours (y compris tout avis officiel d'enquête ou de mise en accusation)&#x202F;?",
            whenYes: [
              [
                {
                  name: "procedureEnCoursHelper",
                  type: "subtext",
                  content:
                    "Veuillez apporter des précisions en particulier concernant la nature des chefs d’accusation, le temps écoulé depuis l’acte répréhensible présumé, la sanction/ peine probable en cas de condamnation, le stade de la procédure, toute autre circonstance atténuante et tout autre facteur aggravant&npsp;:",
                },
              ],
              [
                {
                  name: "procedureEnCoursText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            name: "mesureDisciplinaires",
            type: "yes-no",
            label:
              "Des mesures disciplinaires ont-elles déjà été prises contre vous (ou la personne morale en lien avec vos responsabilités actuelles ou anciennes) ou sont-elles en instance vous concernant (y compris la révocation en tant que membre de l'organe de direction d'une société, le licenciement pour faute professionnelle, l'interdiction de gérer...) ?",
            whenYes: [
              [
                {
                  type: "textarea",
                  label: "Veuillez apporter des précisions",
                  placeholder: "",
                  name: "mesuresDisciplinaires",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "procedureFaillite",
            type: "yes-no",
            label:
              "Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou d’insolvabilité (mesure de redressement ou de liquidation judiciaire) ou d’une procédure similaire&#x202F;?",
            whenYes: [
              [
                {
                  name: "procedureFailliteText",
                  type: "text",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label:
                    "Veuillez apporter des précisions en indiquant si la faillite ou l’insolvabilité était demandée ou non",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "procedureRegleeAmiable",
            type: "yes-no",
            label:
              "La / les procédure(s) que vous avez déclarée(s) ci-dessus a-t-elle / ont-elles été réglée(s) à l’amiable ou dans le cadre d'un règlement alternatif des litiges (par exemple : la médiation)&#x202F;?",
            whenYes: [
              [
                {
                  name: "procedureRegleeAmiableText",
                  type: "text",
                  label: "Veuillez apporter des précisions ci-dessous&nbsp;:",
                  placeholder: "",
                  options: {
                    required: "Ce champ est requis",
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            name: "debiteurInsolvable",
            type: "yes-no",
            label:
              "Avez-vous déjà figuré sur une liste de débiteurs insolvables, faites-vous l’objet d’une inscription négative dans une liste établie par une agence de crédit reconnue ou avez-vous fait l’objet d’une mesure d’ exécution en lien avec une dette&#x202F;?",
            whenYes: [
              [
                {
                  name: "debiteurInsolvableText",
                  type: "text",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Veuillez apporter des précisions ci-dessous&nbsp;",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "interdictionGestion",
            type: "yes-no",
            label:
              "Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une révocation, d’une interdiction de gérer ou d’ une annulation d’enregistrement, d’autorisation, d’adhésion ou de licence concernant l’exercice d’une activité commerciale ou professionnelle ? Une telle procédure est-elle en cours&#x202F;?",
            whenYes: [
              [
                {
                  name: "interdictionGestionText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Veuillez apporter des précisions ci-dessous&nbsp;",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "sanctionSuspension",
            type: "yes-no",
            label:
              "Avez-vous jamais été sanctionné(e) par des autorités publiques ou des organisations professionnelles, ou des enquêtes ou procédures exécutoires sont-elles en cours ou ont-elles déjà été engagées contre vous&#x202F;? En particulier, avez-vous fait l’objet d’une mesure de suspension ou d’exclusion d’une organisation professionnelle en France ou à l’étranger, ou d’une sanction prononcée par un organe central&#x202F;?",
            whenYes: [
              [
                {
                  name: "sanctionSuspensionText",
                  label: "Veuillez donner des détails",
                  placeholder: "",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "debatAspectsHonorabilite",
            type: "yes-no",
            label:
              "L’organe de direction de l’entité soumise à la surveillance prudentielle a-t-il débattu de points particuliers en ce qui concerne des aspects de votre honorabilité ?",
            whenYes: [
              [
                {
                  name: "debatAspectsHonorabiliteText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label:
                    "Veuillez préciser la teneur et les conclusions de ces discussions :",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "concerneDirectement",
            type: "yes-no",
            label:
              "Les actes répréhensibles présumés, la procédure, l'enquête ou les sanctions vous concernent-ils directement ?",
            whenYes: [
              [
                {
                  name: "concerneDirectementCirconstances",
                  type: "textarea",
                  label:
                    "Précisez les circonstances et les raisons de votre implication directe :",
                },
              ],
              [
                {
                  name: "concerneDirectementMesuresPrises",
                  type: "textarea",
                  label:
                    "Décrivez les mesures que vous avez prises pour prévenir les actes répréhensibles et/ou éviter qu’ils soient commis",
                },
              ],
              [
                {
                  name: "concerneDirectementEnseignements",
                  type: "textarea",
                  label:
                    "Qu’auriez-vous pu faire de plus pour éviter ces actes répréhensibles présumés et avez-vous tiré des enseignements de ce qu’il s’est passé ?",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "concerneEntite",
            type: "yes-no",
            label:
              "Les actes répréhensibles présumés, la procédure, l'enquête ou les sanctions concernent-ils des entités dans lesquelles vous occupez ou avez occupé des fonctions ?",
            whenYes: [
              [
                {
                  name: "concerneEntiteRole",
                  type: "textarea",
                  label:
                    "Précisez votre rôle au sein de l'entité et indiquez si vous êtes ou étiez à la tête d'un service ou d'une ligne métier concerné(e) par la procédure (y compris par les sanctions ou les mesures imposées) :",
                },
              ],
              [
                {
                  name: "concerneEntiteTypePoste",
                  type: "text",
                  label:
                    "Étiez-vous membre de l’organe de direction, titulaire d'une fonction-clé ou cadre supérieur lors des actes répréhensibles présumés ?",
                },
              ],
              [
                {
                  name: "concerneEntiteMesuresPrises",
                  type: "textarea",
                  label:
                    "Dans le cadre de votre rôle au sein de l’entité, qu’avez-vous fait pour prévenir les actes répréhensibles présumés et/ou pour éviter qu’ils soient commis ?",
                },
              ],
              [
                {
                  name: "concerneEntiteEnseignements",
                  type: "textarea",
                  label:
                    "Auriez-vous pu en faire plus pour éviter que les actes répréhensibles présumés soient commis et avez-vous tiré des enseignements de ce qu’il s’est passé ?",
                },
              ],
            ],
          },
        ],
      ],
    },
  ],
};

const imasForm = {
  id: "3",
  title: "Votre honorabilité",
  next: "4",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          {
            name: "condamnation",
            type: "yes-no",
            label:
              "Faites-vous ou avez-vous fait l’objet d’une procédure pénale une procédure civile ou administrative (y compris toute procédure en cours, menée à terme ou en appel) ? Cette question couvre les enquêtes, les procédures de sanction ou les mesures prises ou imposées par des autorités publiques ou de surveillance ou des organisations professionnelles (par exemple : avertissement, blâme, etc.) compétentes dans toute juridiction.",
            whenYes: [
              [
                {
                  name: "condamnationType",
                  type: "select",
                  label: "Type de procédure :",
                  values: [
                    { label: "Pénale", value: "criminal" },
                    { label: "Administrative", value: "administrative" },
                    { label: "Civile", value: "civil" },
                    { label: "Autre", value: "other" },
                  ],
                  when: {
                    other: [
                      [
                        {
                          name: "condamnatinTypeAutre",
                          type: "textarea",
                          label: "Veuillez préciser le type de procédure :",
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  name: "condamnationEtape",
                  type: "select",
                  label: "Stade où en est la procédure :",
                  values: [
                    { label: "En cours", value: "pending" },
                    { label: "Menée à terme", value: "concluded" },
                    { label: "En appel", value: "en appel" },
                  ],
                },
              ],
              [
                {
                  name: "condamnationText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label:
                    "Veuillez décrire brièvement les charges et la nature des actes répréhensibles présumés (par exemple&nbsp;: faute intentionnelle ou négligence, etc.) et indiquer à quel stade en est la procédure.",
                },
              ],
              [
                {
                  name: "condamnationAutorite",
                  type: "text",
                  placeholder: "",
                  label:
                    "Veuillez indiquer l’autorité chargée de la procédure et, si possible, la référence du dossier&nbsp;:",
                },
              ],
              [
                {
                  name: "condamnationRelation",
                  type: "select",
                  label:
                    "La procédure vous concerne-t-elle personnellement ou concerne-t-elle une entité à laquelle vous êtes ou avez été associé(e)&#x202F;?",
                  values: [
                    {
                      label: "Oui, la procédure me concerne personellement",
                      value: "related to me",
                    },
                    {
                      label:
                        "Oui, la procédure concerne une entité à laquelle je suis ou ai été associé(e)",
                      value: "related to associated entity",
                    },
                    { label: "Non", value: "not related" },
                  ],
                  when: {
                    "related to me": [
                      [
                        {
                          name: "condamnationRelationDirecteCirconstances",
                          type: "textarea",
                          label:
                            "Précisez dans quelles circonstances et pour quelles raisons l’enquête, la procédure ou les sanctions vous concernent personnellement",
                        },
                      ],
                      [
                        {
                          name: "condamnationRelationDirecteEvitement",
                          type: "textarea",
                          label:
                            "Décrivez les mesures que vous avez prises pour prévenir les actes répréhensibles et/ou éviter qu’ils soient commis",
                        },
                      ],
                      [
                        {
                          name: "condamnationRelationDirecteLesson",
                          type: "textarea",
                          label:
                            "Qu’auriez-vous pu faire de plus pour éviter que ces ces actes répréhensibles présumés soient commis et avez-vous tiré des enseignements de ce qu’il s’est passé ?",
                        },
                      ],
                    ],
                    "related to associated entity": [
                      [
                        {
                          label: "Précisez le nom de l’entité concernée",
                          name: "condamnationRelationEntiteNom",
                          placeholder: "",
                        },
                      ],
                      [
                        {
                          label:
                            "Précisez votre rôle au sein de l’entité et indiquez si vous êtes ou étiez à la tête d’un service ou d’une ligne métier concerné(e) par la procédure (y compris par les sanctions ou les mesures imposées)",
                          name: "condamnationRelationEntiteRole",
                          placeholder: "",
                        },
                      ],
                      [
                        {
                          label:
                            "Étiez-vous membre de l’organe de direction, titulaire d’une fonction-clé ou cadre supérieur(e) lorsque les actes répréhensibles présumés ont été commis ?",
                          name: "condamnationRelationEntiteManagementRole",
                          placeholder: "",
                        },
                      ],
                      [
                        {
                          label:
                            "Dans le cadre de vos fonctions au sein de l’entité, qu’avez-vous fait pour prévenir les actes répréhensibles présumés et/ou pour éviter qu’ils soient commis ?",
                          name: "condamnationRelationEntiteEvitement",
                          placeholder: "",
                        },
                      ],
                      [
                        {
                          label:
                            "Qu’auriez-vous pu faire de plus pour éviter que ces actes répréhensibles présumés soient commis et avez-vous tiré des enseignements de ce qu’il s’est passé ?",
                          name: "condamnationRelationEntiteLesson",
                          placeholder: "",
                          type: "textarea",
                        },
                      ],
                      [
                        {
                          label:
                            "Date du déroulement des actes répréhensibles présumés",
                          name: "condamnationDateMefait",
                          placeholder: "",
                          type: "date",
                        },
                      ],
                      [
                        {
                          label:
                            "Date de la décision, du jugement ou des conclusions (le cas échéant)",
                          name: "condamantionDateJugement",
                          placeholder: "",
                          type: "date",
                        },
                      ],
                      [
                        {
                          label:
                            "Résumé du raisonnement sous-tendant la décision, le jugement ou les conclusions",
                          name: "condamnationResumeJugement",
                          placeholder: "",
                          type: "textarea",
                        },
                      ],
                      [
                        {
                          label:
                            "Sanction ou peine reçue (ou probable en cas de condamnation si les procédures sont en cours)",
                          name: "condamnationSanction",
                          placeholder: "",
                          type: "textarea",
                        },
                      ],
                      [
                        {
                          label:
                            "Précisez l’éventail des sanctions (à savoir la sanction la moins sévère et la section la plus sévère) qui pourraient ou auraient pu être infligées.",
                          name: "condamnationFourchetteSanction",
                          placeholder: "",
                        },
                      ],
                      [
                        {
                          label:
                            "La procédure a-t-elle abouti à un règlement (y compris au moyen d’un règlement extrajudiciaire) ?",
                          name: "condamnationAmiable",
                          placeholder: "",
                          type: "yes-no",
                          whenYes: [
                            [
                              {
                                label:
                                  "Veuillez fournir des détails (notamment les parties au règlement, la date, les montants convenus lors du règlement et toute autre information pertinente)",
                                name: "condamnationAmiableText",
                                placeholder: "",
                              },
                            ],
                          ],
                        },
                      ],
                      [
                        {
                          label:
                            "Veuillez fournir des détails sur votre conduite ultérieure, notamment les enseignements tirés et les mesures correctrices prises.",
                          name: "condamnationConduiteSubsequente",
                          placeholder: "",
                          type: "textarea",
                        },
                      ],
                      [
                        {
                          label:
                            "Décrivez tout autre facteur atténuant ou aggravant à l’aide du Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience",
                          name: "condamnationCirconstanceAttenuanteAggravante",
                          placeholder: "",
                          type: "textarea",
                        },
                      ],
                      [
                        {
                          label:
                            "Les procédures mentionnées ci-dessous ont-elles donné lieu à des récupérations de rémunération ?",
                          name: "condamnationClauseRecuperation",
                          placeholder: "",
                          type: "yes-no",
                          whenYes: [
                            [
                              {
                                label: "Veuillez fournir des détails",
                                name: "condamnationClauseRecuperationText",
                                placeholder: "",
                              },
                            ],
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  name: "condamnationDateMefait",
                  type: "text",
                  placeholder: "",
                  label:
                    "Date et/ou période de déroulement des actes répréhensibles présumés",
                }
              ],
              [
                {
                  name: "condamantionDateJugement",
                  type: "text",
                  placeholder: "",
                  label:
                    "Date de la décision, du jugement ou des conclusions (le cas échéant)",
                },
              ],
              [
                {
                  name: "condamnationResumeJugement",
                  type: "textarea",
                  placeholder: "",
                  label:
                    "Résumé du raisonnement sous-tendant la décision, le jugement ou les conclusions",
                },
              ],
              [
                {
                  name: "condamnationSanction",
                  type: "text",
                  placeholder: "",
                  label:
                    "Sanction ou peine reçue (ou probable en cas de condamnation si les procédures sont en cours)",
                },
              ],
              [
                {
                  name: "condamnationFourchetteSanction",
                  type: "textarea",
                  placeholder: "",
                  label:
                    "Précisez l’éventail des sanctions (à savoir la sanction la moins sévère et la section la plus sévère) qui pourraient ou auraient pu être infligées",
                },
              ],
              [
                {
                  name: "condamnationAmiable",
                  type: "yes-no",
                  placeholder: "",
                  label:
                    "La procédure a-t-elle abouti à un règlement (y compris au moyen d’un règlement extrajudiciaire) ?",
                  whenYes: [
                    [
                      {
                        name: "condamnationAmiableText",
                        type: "text",
                        placeholder: "",
                        label:
                          "Veuillez fournir des détails (notamment les parties au règlement, la date, les montants convenus lors du règlement et toute autre information pertinente).",
                      },
                    ],
                  
                  ]
                },
              ],
              [
                {
                  name: "condamnationConduiteSubsequente",
                  type: "textarea",
                  placeholder: "",
                  label:
                    "Veuillez fournir des détails sur votre conduite ultérieure, notamment les enseignements tirés et les mesures correctrices prises",
                },
              ],
              [
                {
                  name: "condamnationCirconstanceAttenuanteAggravante",
                  type: "textarea",
                  placeholder: "",
                  label:
                    "Décrivez tout autre facteur atténuant ou aggravant à l’aide du Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience<br/><br/><div><span>Exemples de facteurs atténuants :</span><ol type=\"a\" style=\"list-style-type: lower-alpha;\"><li>temps écoulé depuis que les actes répréhensibles présumés ont été commis,</li><li>absence d’enquête ou de procédures ultérieure(s),</li><li>absence de licenciement ou de renvoi d’un poste de confiance.</li></ol></div>",
                },
              ],
              [
                {
                  name: "condamnationClauseRecuperation",
                  type: "yes-no",
                  placeholder: "",
                  label:
                    "Les procédures mentionnées ci-dessous ont-elles donné lieu à des récupérations de rémunération ?",
                  whenYes: [
                    [
                      {
                        name: "condamnationClauseRecuperationText",
                        type: "text",
                        placeholder: "",
                        label:
                          "Veuillez fournir des détails",
                      },
                    ],
                  
                  ]
                },
              ],
            ],
          },
        ],
        [
          {
            name: "mesuresDisciplinaires",
            type: "yes-no",
            label:
              "Faites-vous ou avez-vous personnellement fait l’objet de mesures disciplinaires (y compris d’une exclusion d’une fonction de membre de l’organe de direction ou d’un renvoi d’un poste de confiance) ?",
            whenYes: [
              [
                {
                  name: "mesuresDisciplinairesText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Motif de la mesure disciplinaire",
                },
              ],
              [
                {
                  type: "date",
                  label:
                    "Date ou période du déroulement des actes répréhensibles présumés",
                  name: "procedureEnCoursDate",
                },
                {
                  label:
                    "Avez-vous déposé un recours contre la mesure disciplinaire ?",
                  type: "yes-no",
                  placeholder: "",
                  name: "procedureEnCoursAppeal",
                },
              ],
              [
                {
                  label:
                    "Le cas échéant, veuillez fournir les coordonnées de l’entité impliquée dans la prise des mesures.",
                  name: "procedureEnCoursDetailsEntite",
                  placeholder: "",
                },
              ],
              [
                {
                  label: "Facteurs atténuants ou aggravants éventuels",
                  name: "procedureEnCoursCirconstancesAggravantes",
                  placeholder: "",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "procedureFaillite",
            type: "yes-no",
            label:
              "Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou d’insolvabilité ou d’une procédure similaire&#x202F;?",
            whenYes: [
              [
                {
                  type: "text",
                  label:
                    "Temps écoulé de puis la date de la décision (le cas échéant)",
                  placeholder: "",
                  name: "failliteDepuis",
                },
              ],
              [
                {
                  type: "text",
                  label:
                    "Statut et conclusion (le cas échéant) de la procédure (si elle a été menée à terme, veuillez préciser si elle a conclu à une faute intentionnelle ou à une négligence)",
                  name: "failliteStatut",
                  placeholder: "",
                },
              ],
              [
                {
                  type: "text",
                  label: "Mesures de précaution ou de saisie",
                  name: "failliteMesuresPrecaution",
                  placeholder: "",
                },
              ],
              [
                {
                  type: "select",
                  label:
                    "Qui a déclenché la procédure (vous-même ou l’entité concernée) ?",
                  name: "failliteDeclencheur",
                  values: [
                    { label: "Vous", value: "appointee" },
                    { label: "L’entité concernée", value: "entity" },
                  ],
                },
              ],
              [
                {
                  type: "text",
                  label:
                    "Le cas échéant, veuillez fournir des informations sur l’entité impliquée dans la procédure.",
                  name: "failliteDetailsEntite",
                  placeholder: "",
                },
              ],
              [
                {
                  type: "text",
                  label:
                    "Détails concernant le rôle que vous avez joué, en particulier si vous avez été déclaré(e) responsable de l’insolvabilité de l’entité",
                  name: "failliteImplicationPersonnelle",
                  placeholder: "",
                },
              ],
              [
                {
                  type: "text",
                  label: "Facteurs atténuants ou aggravants éventuels",
                  name: "failliteCirconstancesAggravantes",
                  placeholder: "",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "restructuration",
            type: "yes-no",
            label:
              "Un établissement financier dans lequel vous occupez ou avez occupé une fonction de direction, ou dont vous influencez ou avez influencé concrètement la gestion, de quelque manière que ce soit, a-t-il déjà fait l’objet d’un renflouement ou été soumis à une procédure de restructuration, de rétablissement ou de résolution ?",
            whenYes: [
              [
                {
                  name: "restructurationText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Veuillez apporter des précisions ci-dessous&nbsp;",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "debiteurInsolvable",
            type: "yes-no",
            label:
              "À votre connaissance, avez-vous déjà personnellement figuré sur une liste de débiteurs peu fiables, faites-vous l’objet d’une appréciation négative sur une liste établie par une agence de crédit reconnue ou avez-vous fait l’objet d’une mesure d’exécution pour une dette inscrite sur une liste de ce type&#x202F;?",
            whenYes: [
              [
                {
                  name: "debiteurInsolvableText",
                  type: "text",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Veuillez apporter des précisions ci-dessous&nbsp;",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "interdictionGestion",
            type: "yes-no",
            label:
              "Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une révocation, d’une suspension ou d’une annulation d’enregistrement, d’autorisation, d’adhésion ou de licence concernant l’exercice d’activités commerciales ou professionnelles ? Cette question porte également sur les évaluations d’aptitude antérieures réalisées par d’autres autorités compétentes (y compris à l’étranger).",
            whenYes: [
              [
                {
                  name: "interdictionGestionText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "<ul style='list-style-type:circle'><li>L’expression « à l’étranger » couvre tout territoire qui se trouve en dehors de la juridiction de la BCE ou de l’ACN de l’entité soumise à la surveillance prudentielle où vous êtes nommé(e).</li></ul>Veuillez apporter des précisions ci-dessous&nbsp;",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "blanchiment",
            type: "yes-no",
            label:
              "En dehors des cas mentionnés par ailleurs dans vos réponses, avez-vous déjà été impliqué(e) directement ou indirectement dans une situation ayant suscité des préoccupations ou des soupçons quant à d’éventuelles activités de blanchiment de capitaux ou de financement du terrorisme&#x202F;?",
            whenYes: [
              [
                {
                  name: "blanchimentText",
                  label: "Veuillez donner des détails",
                  placeholder: "",
                },
              ],
            ],
          },
        ],
        [
          {
            name: "transparence",
            type: "yes-no",
            label:
              "Avez-vous déjà personnellement omis de déclarer toute information que l’autorité de surveillance aurait été en droit d’attendre&#x202F;?",
            whenYes: [
              [
                {
                  name: "transparenceText",
                  type: "textarea",
                  placeholder: "",
                  autoFocus: true,
                  options: {
                    required: "Ce champ est requis",
                  },
                  label: "Veuillez fournir des détails",
                },
              ],
            ],
          },
        ],
      ],
    },
  ],
};

export default (candidature) => {
  if (candidature.portalTarget === "acpr") {
    return acprForm;
  } else {
    return imasForm;
  }
};
