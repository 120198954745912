import EntiteSelect from "../components/form/EntiteSelect";
import FonctionSelector from "../components/FonctionSelector";
import NbJoursParAn from "../components/NbJoursParAn";
import TempsConsacre from "../components/form/TempsConsacre";
import ShowTempsConsacre from "../components/ShowTempsConsacre";
import TotalTempsConsacre from "../components/form/TotalTempsConsacre";

import stores from "../stores";

import { FONCTIONS } from "../lib/fonctionNames";
import NbReunionDirigeantsParAn from "components/NbReunionDirigeantsParAn";
import NbJoursReunionsDirigeantsParAn from "components/NbJoursReunionsDirigeantsParAn";
import MandatDateDeFin from "components/MandatDateDeFin";
import { targetsIMAS } from "lib/utils";

const { listEntites } = stores.forms.getState();

let entites = [];

(async () => {
  entites = await listEntites();
})();

export const RESPONSABILITES = {
  "president-du-comite-d-audit": "Président du comité d’audit",
  "membre-du-comite-d-audit": "Membre du comité d’audit",
  "president-du-comite-des-remunerations":
    "Président du comité des rémunérations",
  "membre-du-comite-des-remunerations": "Membre du comité des rémunérations",
  "president-du-comite-des-risques": "Président du comité des risques",
  "membre-du-comite-des-risques": "Membre du comité des risques",
  "president-du-comite-des-nominations": "Président du comité des nominations",
  "membre-du-comite-des-nominations": "Membre du comité des nominations",
  "president-du-comite-rse": "Président du comité RSE",
  "membre-du-comite-rse": "Membre du comité RSE",
  autre: "Autre",
};

const RESPONSABILITES_CHOICES = Object.entries(RESPONSABILITES).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const TYPES = {
  "mandat-executif": "Fonction exécutive",
  "mandat-non-executif": "Fonction non-exécutive",
  "mandat-autre": "Fonction non constitutive d’un mandat social",
};

export const TYPES_CHOICES = Object.entries(TYPES).map(([value, label]) => ({
  value,
  label,
}));

export const DECOMPTE_PRIVILEGIE = {
  "part-of-group": "Oui, au sein d’un groupe",
  "part-of-institutional-scheme":
    "Oui, au sein d’un système de protection institutionnel",
  "qualifying-holding": "Oui, paricipation qualifiée",
  "no-not-part-of-anything":
    "Non, ne fait pas partie d’un groupe, d’un système de protection institutionnel ou d’un établissement détenant une participation qualifiée",
  "no-counting": "Non, pas de décompte privilégié",
};

export const DECOMPTE_PRIVILEGIE_CHOICES = Object.entries(TYPES).map(
  ([value, label]) => ({ value, label })
);

export default {
  id: "10",
  title: "Vos fonctions",
  next: "11",
  defaultValues: {
    overrideJoursParAn: "no",
    totalNbJoursParAnManuel: NaN,
    mandatFonction: {
      responsabilitesSupplementaires: {},
    },
  },
  sections: [
    {
      title:
        "Fonctions exécutives, non exécutives et autres activités professionnelles",
      rows: [
        [
          {
            type: "info-message",
            label:
              "Veuillez indiquer en premier lieu le poste ou le mandat dans l’organe de direction pour lequel cette candidature est complétée, puis l’ensemble des autres fonctions et autres activités professionnelles de la personne nommée (renouvelée).",
          },
        ],
        [
          {
            type: "array",
            name: "mandatFonction",
            collapsable: true,
            keepTheFirstOne: true,
            collapsed: (item) => {
              let entiteNom;

              if (item.entiteId) {
                let entite = entites.find(({ id }) => id === item.entiteId);

                entite && (entiteNom = entite.denominationSociale);
              } else if (item.entiteNom) {
                entiteNom = item.entiteNom;
              }

              const fonction =
                item.fonctionId === "autre"
                  ? item.intituleFonction
                  : FONCTIONS[item.fonctionId];

              return `${entiteNom || "Entité"} - ${fonction || "Fonction"}`;
            },
            fields: [
              [{ name: "id", type: "hidden" }],
              [
                {
                  type: "entite-select",
                  name: "entiteId",
                  label: "Entité",
                  helpText:
                    "Sélectionnez l’entité dans laquelle vous avez exercé ce mandat/cette fonction",
                  Component: EntiteSelect,
                  when: {
                    null: [
                      [
                        {
                          name: "entiteNom",
                          label: "Nom de l’entité",
                          options: { required: "Ce champ est requis" },
                        },
                      ],
                      [
                        {
                          name: "entiteDenominationSociale",
                          label: "Dénomination Sociale",
                          options: { required: "Ce champ est requis" },
                        },
                      ],
                      [
                        {
                          name: "groupeAppartenance",
                          label: "Groupe d’appartenance",
                        },
                        {
                          name: "pourcentageDetention",
                          label: "Pourcentage de détention",
                        },
                      ],
                      [
                        {
                          name: "entiteCIB",
                          label: "CIB",
                          helpText: "Si applicable",
                        },
                        {
                          name: "entiteSIREN",
                          label: "SIREN",
                          helpText: "Si applicable",
                        },
                        {
                          name: "entiteLEI",
                          label: "LEI",
                          helpText: "Si applicable",
                        },
                      ],
                      [
                        {
                          name: "entiteFormeJuridique",
                          label: "Forme Juridique",
                          options: { required: "Ce champ est requis" },
                        },
                        {
                          name: "tailleEntite",
                          label: "Taille de l’entité",
                          options: { required: "Ce champ est requis" },
                        },
                      ],
                      [
                        {
                          name: "pays",
                          label: "Pays",
                          options: { required: "Ce champ est requis" },
                        },
                      ],
                      [
                        {
                          name: "descriptionActiviteEntite",
                          type: "textarea",
                          label: "Description de l’activité de l’entité",
                          options: { required: "Ce champ est requis" },
                        },
                        {
                          name: "entiteCotee",
                          type: "yes-no",
                          label: "Entité cotée",
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  name: "fonctionId",
                  label: "Fonction",
                  type: "custom",
                  Component: FonctionSelector,
                  options: { required: "Ce champ est requis" },
                  when: {
                    autre: [
                      [
                        {
                          name: "intituleFonction",
                          label: "Intitulé de la fonction",
                        },
                        {
                          name: "typeFonction",
                          label: "Type de fonction",
                          type: "select",
                          values: [
                            {
                              label: "Mandat exécutif",
                              value: "mandat-executif",
                            },
                            {
                              label: "Mandat non exécutif",
                              value: "mandat-non-executif",
                            },
                            { label: "Autre", value: "autre" },
                          ],
                          options: {
                            required:
                              "Veuillez selectionner un type de fonction",
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  name: "descriptionActivite",
                  condition: (name) => {
                    return name === "mandatFonction[0].descriptionActivite";
                  },
                  label:
                    "Veuillez fournir une description détaillée des missions, des responsabilités et des liens hiérarchiques associés à la fonction. Veuillez indiquer quelles autres fonctions la personne nommée / personne à nommer exercera éventuellement au sein de l’entité soumise à la surveillance prudentielle.",
                  options: {
                    required: "Ce champ est requis",
                  },
                },
              ],
              [
                {
                  name: "nbReunionsDirigantsParAn",
                  label: "Nombre de réunions de dirigeants par an",
                  type: "custom",
                  Component: NbReunionDirigeantsParAn,
                  options: {
                    min: 0,
                    max: {
                      value: 365,
                      message: "Ce nombre ne peut dépasser 365",
                    },
                  },
                },
                {
                  name: "nbJoursReunionsDirigeantsParAn",
                  label: "Temps consacré aux réunions de dirigeants par an",
                  helpText: "une réunion = 1/2 journée",
                  Component: NbJoursReunionsDirigeantsParAn,
                  options: {
                    min: 0,
                    max: {
                      value: 365,
                      message: "Ce nombre ne peut dépasser 365",
                    },
                  },
                },
                {
                  name: "nbJoursParAn",
                  label: "Jours par an",
                  type: "custom",
                  Component: NbJoursParAn,
                  options: {
                    required: "Ce champ est requis",
                    min: 0,
                    max: {
                      value: 365,
                      message: "Ce nombre ne peut dépasser 365",
                    },
                  },
                },
                {
                  name: "tempsJoursAnnees",
                  type: "hidden",
                },
                {
                  type: "blank",
                },
              ],
              [
                {
                  name: "debutMandat",
                  label: "Début du mandat",
                  helpText: "Dans le doute, saissez le 1er janvier",
                  type: "date",
                  options: {
                    required: "Veuillez indiquer la date de début du mandat",
                  },
                },
                {
                  name: "finMandat",
                  label: "Fin du mandat",
                  helpText: "Dans le doute, saissez le 1er janvier",
                  type: "custom",
                  Component: MandatDateDeFin,
                  options: {
                    required: "Veuillez indiquer la date de fin du mandat",
                    min: {
                      value: new Date(),
                      message: "Les fonctions ou mandats ne peuvent être échus",
                    },
                    max: {
                      value: "2999-12-31T23:59:59.999",
                      message: "La date d’échéance est trop lointaine",
                    },
                  },
                },
                {
                  name: "finIndeterminee",
                  label: "Durée indéterminée",
                  type: "checkbox",
                },
              ],
              [
                {
                  name: "tempsConsacre",
                  label: "Total du temps consacré à la fonction ou au mandat",
                  type: "custom",
                  Component: TempsConsacre,
                  options: {},
                },
              ],
              [
                {
                  name: "responsabilitesSupplementaires",
                  label:
                    "Responsabilités supplémentaires telles que l’appartenance à des comités, des fonctions de président, etc.&nbsp;:",
                  type: "array",
                  condition: targetsIMAS,
                  fields: [
                    [
                      {
                        name: "responsabilite",
                        label: "Responsabilité supplémentaire",
                        type: "select",
                        values: RESPONSABILITES_CHOICES,
                        when: {
                          autre: [
                            [
                              {
                                name: "custom",
                                label: "Intitulé de la fonction",
                              },
                            ],
                          ],
                        },
                      },
                      {
                        name: "nbReunionsParAn",
                        label: "Nombres de réunions par an",
                        type: "number",
                        min: 1,
                        max: 365,
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  name: "decomptePrivilegie",
                  type: "select",
                  values: DECOMPTE_PRIVILEGIE_CHOICES,
                },
              ],
              [
                {
                  name: "mandatMilliardEuros",
                  type: "yes-no",
                  label:
                    "Ce mandat se trouve-t-il dans un établissement de crédit, une entreprise d’investissement ou une société de financement, établi en France, dont le total de bilan, social ou consolidé, est supérieur, depuis 2 exercices consécutifs, à 15 milliards d’euros&#x202F;?",
                },
              ],
              [
                {
                  name: "showTempsConsacre",
                  label: "Temps consacré à cette foncion ou ce mandat",
                  type: "custom",
                  options: {},
                  Component: ShowTempsConsacre,
                },
              ],
              [
                {
                  name: "informationsComplementaires",
                  label: "Infomations complémentaires",
                  type: "textarea",
                },
              ],
            ],
          },
        ],
        [
          {
            type: "hidden",
            name: "overrideJoursParAn",
          },
          {
            type: "hidden",
            name: "totalNbJoursParAnManuel",
          },
          {
            type: "custom",
            name: "totalTempsConsacre",
            label:
              "Total du temps consacré à vos fonctions, incluant la fonction concernée par cette candidature&nbsp;:",
            Component: TotalTempsConsacre,
          },
        ],
      ],
    },
  ],
};
