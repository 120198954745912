import { targetsIMAS, targetsACPR } from "../lib/utils";
import etapeExperience56 from "./etape-experience-5-6";


export default (candidature) => {

  return {
    "id": "6",
    "title": candidature.portalTarget === "acpr"
      ? "Autres expériences pertinentes hors du secteur financier au cours des 10 dernières années" 
      : "Expérience pertinente en dehors des secteurs de la banque et/ou de la finance (par exemple : postes universitaires, mandats politiques, autres mandats non commerciaux ou autre expérience spécialisée)",
    "next": "8",
    "defaultValues": {},
    "sections": [
      {
        "title": null,
        "rows": [
          [
            {
              "name": "candidatExperienceAutre",
              "type": "array",
              "fields": etapeExperience56
            }
          ]
        ]
      }
    ]
  }

}
