import Step1 from "./etape-1.js";
import Step2 from "./etape-2.json";
import Step3 from "./etape-3.js";
import Step4 from "./etape-4.json";
import Step5 from "./etape-5.js";
import Step6 from "./etape-6.js";
import Step7 from "./etape-7.json";
import Step8 from "./etape-8.js";
import Step9 from "./etape-9.js";
import Step10 from "./etape-10.js";
import Step11 from "./etape-11.json";

const forms = {
  "etape-1": Step1,
  "etape-2": Step2,
  "etape-3": Step3,
  "etape-4": Step4,
  "etape-5": Step5,
  "etape-6": Step6,
  "etape-7": Step7,
  "etape-8": Step8,
  "etape-9": Step9,
  "etape-10": Step10,
  "etape-11": Step11,
};

export const numberOfSteps = Object.keys(forms).length;

export default forms;
