import { targetsIMAS, targetsACPR } from "../lib/utils";

export default [
  [
    {
      name: "id",
      type: "hidden",
    },
  ],
  [
    {
      name: "fonction",
      label: "Fonction/Mandat",
      autoFocus: true,
      options: { required: "Ce champ est requis" },
    }
  ],
  [
    {
      label: "Domaines d’expertise et principales responsabilités",
      name: "domainesExpertise",
    }
  ],
  [
    {
      name: "organisation",
      label:
        "Organisation, entreprise, etc... dans laquelle le mandat est exercé",
      options: { required: "Ce champ est requis" },
    },
  ],
  [
    {
      name: "activiteEntreprise",
      label: "Domaine d’activité",
      condition: targetsIMAS,
    }
  ],
  [
    {
      name: "groupeAppartenance",
      label: "Groupe d’appartenance",
      condition: targetsACPR,
    },
    {
      name: "pourcentageDetention",
      label: "Pourcentage de détention",
      condition: targetsACPR,
      type: "number",
      options: {
        min: 0,
        max: 100,
      },
    },
  ],
  [
    {
      name: "formeJuridique",
      label: "Forme Juridique",
      condition: targetsACPR,
    },
    {
      name: "paysEntite",
      label: "Pays",
      condition: targetsACPR,
    },
    
  ],
  [
    {
      name: "activiteEntreprise",
      label: "Domaine d’activité principal",
      condition: targetsACPR,
    },
  ],
  [
    {
      name: "niveauHierarchique",
      label: "Rang/Niveau hiérarchique du poste",
      type: "select",
      values: [
        { value: "senior", label: "Poste de très haut niveau" },
        { value: "high", label: "Poste de haut niveau" },
        {
          value: "managerial",
          label: "Autre poste de direction",
        },
        { value: "other", label: "Autre" },
      ],
    },
  ],
  [
    {
      name: "taille",
      label: "Taille (en millions d’euros)",
    },
    {
      name: "nombreEmployes",
      label: "Nombre d’employés placés sous sa responsabilité",
    },
  ],

  [
    {
      name: "dateDebut",
      label: "Date de début",
      type: "date",
      placeholder: "dd-mm-yyyy",
      options: {
        required: "Ce champ est requis",
        max: "today",
      },
    },
    {
      name: "dateFin",
      label: "Date de fin",
      type: "date",
      placeholder: "dd-mm-yyyy",
      options: {
        required: "Ce champ est requis",
      },
    },
  ],
  [
    {
      name: "motifFin",
      label: "Motif de la fin du contrat/mandat",
    },
  ],
]
