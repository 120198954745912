import { targetsIMAS, targetsACPR } from "../lib/utils";
import etapeExperience56 from "./etape-experience-5-6";

export default {
  id: "5",
  title:
    "Expérience dans le domaine bancaire/financier au cours des 10 dernières années",
  next: "6",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          {
            name: "candidatExperienceBancaire",
            type: "array",
            fields: etapeExperience56
          },
        ],
      ],
    },
  ],
};
