const { REACT_APP_API_URL } = process.env;

const ROUTES = {
  USERS: `${REACT_APP_API_URL}/users`,
  AUTH: `${REACT_APP_API_URL}/auth`,
  CANDIDATES: `${REACT_APP_API_URL}/candidats`,
  CANDIDATURES: `${REACT_APP_API_URL}/candidatures`,
  ENTITES: `${REACT_APP_API_URL}/entites`,
  ACCREDITATIONS: `${REACT_APP_API_URL}/accreditations`,
};

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const queryParams = (qs) => {
  if (qs && Object.keys(qs).length > 0) {
    return `?${Object.entries(qs)
      .map(([key, value]) => [key, encodeURIComponent(value)].join("="))
      .join("&")}`;
  }
  return "";
};

function parseBody(body, headers) {
  if (!body) {
    return undefined;
  }

  if (headers && headers["Content-Type"] === "multipart/form-data") {
    return body;
  }

  return JSON.stringify(body);
}

function request(
  method,
  url,
  { token, body, query, fileName, headers = {} } = {}
) {
  const options = {
    method: method.toUpperCase(),
    headers: token
      ? {
          ...DEFAULT_HEADERS,
          ...headers,
          Authorization: `Bearer ${token}`,
          // role,
        }
      : { ...DEFAULT_HEADERS, ...headers },
    body: parseBody(body, headers),
  };

  if (options.headers["Content-Type"] === "multipart/form-data") {
    delete options.headers["Content-Type"];
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const error = new Error("Le service est temporairement indisponible");
      error.type = "Request Timeout";
      // storeApi.setState({
      //   msgAppWide: { message: error.message, type: 'error' },
      // })
      reject(error);
    }, 30000);

    url += queryParams(query);
    fetch(url, options)
      .then(async (response) => {
        clearTimeout(timeout);

        if (response.status === 404) {
          response.text().then((text) => {
            const error = new Error(text);
            error.type = "Not found";
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error);
          });
        } else if (response.status >= 200 && response.status <= 202) {
          const fileType = ["jpeg", "png", "jpg", "pdf"].find((type) =>
            response.headers.get("Content-Type").includes(type)
          );
          if (fileType) {
            const blob = await response.blob();
            const fileUrl =
              typeof window !== "undefined" && window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = fileUrl;
            // the filename you want
            a.download = `${fileName}`;
            document.body.appendChild(a);
            a.click();

            typeof window !== "undefined" &&
              window.URL.revokeObjectURL(fileUrl);
            resolve();
          } else {
            response.json().then((json) =>
              resolve({
                results: json,
                total: response.headers.get("X-Total-Count"),
              })
            );
          }
        } else if (response.status >= 203 && response.status < 300) {
          try {
            resolve({
              results: { success: true },
              total: response.headers.get("X-Total-Count"),
            });
          } catch (error) {
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error);
          }
        } else {
          response.text().then((text) => {
            let error;

            try {
              const {
                error: { message },
                redirect,
                redirectMessage,
              } = JSON.parse(text);

              error = new Error(message);
              error.redirect = redirect;
              error.redirectMessage = redirectMessage;
            } catch (_) {
              error = new Error(text);
            }

            reject(error);
          });
        }
      })
      .catch((error) => {
        error = new Error("Le service est temporairement indisponible");
        error.type = "Client Error";
        // storeApi.setState({
        //   msgAppWide: { message: error.message, type: 'error' },
        // })
        reject(error);
      });
  });
}

function post(url, params) {
  return request("post", url, params);
}

/* eslint-disable no-unused-vars */
function put(url, params) {
  return request("put", url, params);
}

function patch(url, params) {
  return request("patch", url, params);
}

function get(url, params) {
  return request("get", url, params);
}

function del(url, params) {
  return request("delete", url, params);
}

async function getOne({ route, id, token, query }) {
  const req = await get(`${route}/${id}`, { token, query });

  if (req) {
    return req.results;
  }

  return null;
}

// DEBUG
function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * @class
 * @name Api
 * The weird wraping with a WeakMap is to keep the user token private
 */
export default {
  signin: (params) => {
    if (params.email === "error@mail.com") {
      throw new Error("Email invalide");
    }
    return post(`${REACT_APP_API_URL}/login`, { body: params });
  },
  signup: (params) => {
    return post(`${ROUTES.USERS}`, { body: params });
  },
  passwordForgotRequest: (params) => {
    return post(`${ROUTES.USERS}/reinit-password`, { body: params });
  },
  validateRecoveryCode: ({ email, recoveryCode }) => {
    return get(`${ROUTES.USERS}/reinit-password/${email}/${recoveryCode}`);
  },
  changePassword: (token, body) => {
    return patch(`${ROUTES.USERS}/reinit-password`, { token, body });
  },
  updateUser: (token, params) => {
    return patch(`${ROUTES.USERS}`, { token, body: params });
  },
  getUserFromToken: (token) => {
    return get(`${ROUTES.CANDIDATES}/auth/${token}`);
  },
  revokeMagicLink: (token) => {
    // Token is stored in experiencesAutre field
    return get(`${ROUTES.CANDIDATES}/revoke/${token}`);
  },
  saveFormToDb: (token, form) => {
    return post(`${ROUTES.CANDIDATURES}/save-form`, { token, body: form });
  },
  uploadFile: (token, formData) => {
    return post(`${ROUTES.CANDIDATURES}/upload-files`, {
      token,
      body: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
  },
  removeFile: (token, body) => {
    return post(`${ROUTES.CANDIDATURES}/remove-file`, { token, body });
  },
  askAccreditation: ({ firstName, lastName, email }) => {
    return post(`${ROUTES.ENTITES}/ask-accreditation`, {
      body: { lastName, firstName, email },
    });
  },
  registerUserFromToken: (params) => {
    return post(`${ROUTES.USERS}/register`, {
      body: params,
    });
  },
  downloadFile: (token, fileId, fileName) => {
    return get(`${ROUTES.CANDIDATES}/download-file/${fileId}`, {
      token,
      fileName,
    });
  },
  // ENTITES
  listEntites: ({ token }) => {
    return get(`${ROUTES.ENTITES}/all`, { token });
  },
  // CUMUL DES MANDATS
  computeDedicatedDays: ({ token, id, body }) => {
    return post(`${ROUTES.CANDIDATURES}/${id}/dedicated-days`, {
      token,
      body,
    });
  },
  acceptAccreditation: (token, formData) => {
    return post(`${ROUTES.ACCREDITATIONS}/accept`, {
      token,
      body: formData,
    });
  },
  declineAccreditation: (token, userAccreditationtoken) => {
    return post(`${ROUTES.ACCREDITATIONS}/decline`, {
      token,
      body: { token: userAccreditationtoken },
    });
  },

  registerAccreditation: ({
    firstName,
    lastName,
    email,
    entiteId,
    role,
    token,
  }) => {
    return post(`${ROUTES.ACCREDITATIONS}/register`, {
      body: { lastName, firstName, email, entiteId, role, token },
    });
  },

  requestAccess: (email) => {
    return post(`${ROUTES.ACCREDITATIONS}/request-access`, {
      body: { email },
    });
  },

  checkRegistrationToken: (token) => {
    return post(`${ROUTES.ACCREDITATIONS}/check-token`, {
      body: { token },
    });
  },
  checkLoginToken: (token) => {
    return post(`${ROUTES.USERS}/check-login-token`, {
      body: { token },
    });
  },
  checkValidationCode: ({ code, candidatureToken, token }) => {
    return post(`${ROUTES.CANDIDATES}/check-sms-code`, {
      token,
      body: { code, candidatureToken },
    });
  },
  setPassword: ({ token, password }) => {
    return post(`${ROUTES.USERS}/set-password`, {
      body: { token, password },
    });
  },
  resetPassword: ({ token, password }) => {
    return post(`${REACT_APP_API_URL}/reset-password`, {
      body: { token, password },
    });
  },
  isUserVerified: (token) => {
    return get(`${ROUTES.CANDIDATES}/is-user-verified`, {
      token,
    });
  },
  sendNewCode: (token, type = 'sms') => {
    return get(`${ROUTES.CANDIDATES}/send-new-code?type=${type}`, {
      token,
    });
  },
  finishCandidature: (token, id) => {
    return put(`${ROUTES.CANDIDATURES}/${id}/finish`, {
      token,
    });
  },
};
