import React, { useEffect, useState, useCallback, useRef } from "react";

import Input from "./Input";
import TextArea from "./TextArea";
import YesNoInput from "./YesNoInput";
import Collapsable from "./Collapsable";
import Rating from "./Rating";
import Select from "./Select";
import Subtext from "./Subtext";
import Files from "./File";
import Date from "./Date";
import { Controller, useFieldArray } from "react-hook-form";

import { v4 as uuid } from "uuid";

import { getDeepValue } from "../utils";
import EntiteSelect from "./EntiteSelect";
import CheckBox from "./CheckBox";
import { useUser } from "stores/user";

const NO_OP = () => {};

InputArray.defaultProps = {
  inputs: [],
  errors: {},
};

function InputArray({
  inputs,
  errors,
  name,
  label,
  idx,
  form,
  setCurrentStepData,
  collapsable,
  collapsed,
  keepTheFirstOne,
}) {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({ control, name });

  // accordionState maintains the state of "which" item is shown expanded
  // when the others are collapsed
  const [accordionState, setAccordionState] = useState(null);
  const [focusNew, setFocusNew] = useState(false);

  // refs are DOM elements of each label child
  // Used to figureout if focus is within
  // on of the label child, and therefore,
  // whether to collapse all items upon clicking outside
  const refs = useRef({});

  const scrollToLabel = useCallback(
    (fieldId) => {
      if (refs.current && refs.current[fieldId]) {
        refs.current[fieldId].scrollIntoView();
      }
    },
    [refs],
  );

  const focusFirstInput = useCallback(
    (fieldId) => {
      if (refs.current && refs.current[fieldId]) {
        const row = refs.current[fieldId].querySelector(".form-row");
        const input = row.querySelector("label input, label textarea");

        if (input && input.focus) {
          input.focus();
        }
      }
    },
    [refs],
  );

  // If the user clicked the "add button",
  // a new field is added,
  // and we want to expand it,
  // and to scroll to it
  useEffect(() => {
    if (focusNew) {
      const newField = fields[fields.length - 1];
      if (!newField) {
        console.warning("Cannot find a new field to focus onto");
        return;
      }
      setAccordionState(newField.id);

      setTimeout(() => {
        scrollToLabel(newField.id);
        focusFirstInput(newField.id);
      }, 50);

      setFocusNew(false);
    }
  }, [focusNew, fields, accordionState, scrollToLabel, focusFirstInput]);

  // Expand an item on clicking it
  // Collapses the others
  const onClick = useCallback(
    (fieldId) => {
      if (accordionState !== fieldId) {
        setAccordionState(fieldId);
        scrollToLabel(fieldId);
        focusFirstInput(fieldId);
      }
    },
    [accordionState, setAccordionState, scrollToLabel, focusFirstInput],
  );

  // Use a dom event listener to detect whether or not
  // to collapse everything when the user click on the document.
  //   If the active element (with focus) is within a child,
  //   expand the parent label.
  //
  //   Otherwise, collapse all the things
  useEffect(() => {
    const collapseAll = () => {
      !focusNew && setAccordionState(null);
    };

    const handler = function (_event) {
      if (
        !Object.values(refs.current).find(
          (r) => r && r.contains(document.activeElement),
        )
      ) {
        collapseAll();
      }
    };

    document.addEventListener("click", handler);

    return () => document.removeEventListener("click", handler);
  }, [accordionState, focusNew]);

  return (
    <div className="w-full">
      {label && <label dangerouslySetInnerHTML={{ __html: label }} />}
      {fields.map((field, fidx) => {
        // if (!field.id) field.id = uuid();
        const n = `${name}[${fidx}]`;
        const hasErrors = Object.keys(errors).some((f) => f.startsWith(n));

        return (
          <label
            id={`label-${field.id}`}
            ref={(r) => (refs.current[field.id] = r)}
            htmlFor={field.id}
            key={`${idx}-${field.id}`}
            tabIndex={fidx}
            onClick={() => collapsable && onClick(field.id)}
            className="flex flex-col w-full pb-3 border-b border-dashed collapsable-array border-input-border"
          >
            {!(keepTheFirstOne && fidx === 0) && (
              <button
                type="button"
                style={{ position: "relative", top: "2.5em" }}
                className="relative self-end action-button small danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    window.confirm(
                      "Confirmez-vous la suppression de cet élément ?",
                    )
                  ) {
                    const currentObj = form.getValues();
                    const localObj = getDeepValue(currentObj, name, []);

                    localObj.splice(fidx, 1);
                    remove(fidx);
                    setCurrentStepData(currentObj);

                    if (collapsable) {
                      setAccordionState(null);
                    }
                  }
                }}
              >
                Supprimer
              </button>
            )}
            {collapsable && (
              <>
                <input
                  id={field.id}
                  type="checkbox"
                  checked={accordionState === field.id}
                  onChange={NO_OP}
                  className="hidden"
                />
                <div className="collapsed" title="Cliquez pour modifier">
                  <span>
                    {collapsed &&
                      typeof collapsed === "function" &&
                      collapsed(field)}
                  </span>
                  {hasErrors ? (
                    <span className="text-red-600 text-small">
                      Cliquez pour corriger les erreurs
                    </span>
                  ) : (
                    <span className="opacity-50 text-small">
                      Cliquez pour modifier
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="expandable">
              {inputs.map((row, r) => (
                <FormRow
                  key={`${field.id}-${r}`}
                  namePrefix={`${name}[${fidx}]`}
                  inputs={row}
                  idx={`${field.id}-${fidx}-${r}`}
                  form={form}
                  errors={errors}
                  field={field}
                  setCurrentStepData={setCurrentStepData}
                />
              ))}
            </div>
          </label>
        );
      })}
      <button
        type="button"
        className="mt-4 action-button"
        onClick={() => {
          append({ id: uuid() });
          setFocusNew(true);
        }}
      >
        Ajouter
      </button>
    </div>
  );
}

function isDefaultInput(type) {
  return ![
    "array",
    "blank",
    "checkbox",
    "collapsable",
    "custom",
    "date",
    "entite-select",
    "file",
    "info-message",
    "rating",
    "select",
    "subtext",
    "textarea",
    "warn-message",
    "yes-no",
  ].includes(type);
}

FormRow.defaultProps = {
  inputs: [],
  errors: {},
};

function formatNamePrefix(namePrefix) {
  if (!namePrefix) return "";

  if (namePrefix.endsWith(".")) return namePrefix;

  return namePrefix + ".";
}

export default function FormRow({
  inputs,
  idx,
  form,
  errors,
  namePrefix,
  setCurrentStepData,
  disable,
}) {
  const { register, watch, setValue, control } = form;
  let prefix = formatNamePrefix(namePrefix);

  const { candidature } = useUser(({ candidature }) => ({ candidature }));

  disable = disable || [];
  const displayableInputs = inputs.filter((i) => {
    if (i.condition && typeof i.condition === "function") {
      return i.condition(i.name, candidature);
    }

    return true;
  });

  const inputsLength = displayableInputs.filter(
    (i) => i.type !== "hidden",
  ).length;

  return (
    <div key={`section-${idx}`} className={`form-row col-${inputsLength}`}>
      {displayableInputs.map((input, iidx) => {
        const inputName = prefix + input.name;

        return (
          <React.Fragment key={`section-${idx}-${iidx}-row`}>
            {isDefaultInput(input.type) && (
              <Input
                key={`section-${idx}-input`}
                form={form}
                //ref={register(input.options)}
                type={input.type || "text"}
                name={inputName}
                label={input.label}
                placeholder={input.placeholder}
                helpText={input.helpText}
                value={form.watch(inputName)}
                error={errors[inputName] && errors[inputName].message}
                autoFocus={input.autoFocus}
                disabled={disable.includes(input.name)}
                rules={input.options}
                condition={input.condition}
                {...input.options}
              />
            )}
            {input.type === "subtext" && (
              <Subtext
                key={`section-${idx}-subtext`}
                className={input.className}
                disabled={disable.includes(input.name)}
                condition={input.condition}
                content={input.content}
              />
            )}
            {input.type === "checkbox" && (
              <CheckBox
                key={`section-${idx}-checkbox`}
                form={form}
                label={input.label}
                helpText={input.helpText}
                name={inputName}
              />
            )}
            {input.type === "yes-no" && (
              <YesNoInput
                key={`section-${idx}-${iidx}-yes-no`}
                input={input}
                name={inputName}
                label={input.label}
                register={register}
                value={watch(inputName)}
                setValue={setValue}
                error={errors[inputName] && errors[inputName].message}
                form={form}
                required={!!input.options && !!input.options.required}
                whenYes={
                  input.whenYes &&
                  input.whenYes.map((row, ynridx) => (
                    <FormRow
                      key={`section-${idx}-${iidx}-yes-no-${ynridx}-yes`}
                      inputs={row}
                      namePrefix={prefix}
                      idx={`${idx}-${iidx}-yes`}
                      ridx={ynridx}
                      form={form}
                      errors={errors}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))
                }
                whenNo={
                  input.whenNo &&
                  input.whenNo.map((row, ynridx) => (
                    <FormRow
                      key={`section-${idx}-${iidx}-yes-no-${ynridx}-no`}
                      inputs={row}
                      namePrefix={prefix}
                      idx={`${idx}-${iidx}-no`}
                      ridx={ynridx}
                      form={form}
                      errors={errors}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))
                }
                {...input.options}
              /> // YesNoInput
            )}
            {input.type === "array" && (
              <InputArray
                key={`section-${idx}-${iidx}-array`}
                idx={`section-${idx}-${iidx}-array`}
                inputs={input.fields}
                name={inputName}
                label={input.label}
                form={form}
                errors={errors}
                collapsable={input.collapsable}
                collapsed={input.collapsed}
                setCurrentStepData={setCurrentStepData}
              />
            )}
            {input.type === "info-message" && (
              <div
                className="p-2 text-sm bg-gray-200 opacity-75 form-row"
                dangerouslySetInnerHTML={{ __html: input.label }}
              />
            )}
            {input.type === "warn-message" && (
              <div
                className="p-2 text-sm text-yellow-900 bg-yellow-400 opacity-75 form-row"
                dangerouslySetInnerHTML={{ __html: input.label }}
              />
            )}
            {input.type === "hidden" && (
              <Input
                className="hidden"
                key={`section-${idx}-hidden`}
                form={form}
                //ref={register(input.options)}
                type={input.type || "text"}
                name={inputName}
                label={input.label}
                placeholder={input.placeholder}
                helpText={input.helpText}
                value={form.watch(inputName)}
                error={errors[inputName] && errors[inputName].message}
                autoFocus={input.autoFocus}
                {...input.options}
              />
            )}
            {input.type === "rating" && (
              <Rating
                key={`section-${idx}-${iidx}-rating`}
                idx={`section-${idx}-${iidx}-rating`}
                name={inputName}
                label={input.label}
                error={errors[inputName] && errors[inputName].message}
                required={!!input.options && !!input.options.required}
                form={form}
                choices={input.choices}
              />
            )}
            {input.type === "select" && (
              <>
                <Controller
                  rules={input.options}
                  render={(props) => (
                    <Select
                      {...input}
                      {...props}
                      onChange={(value) => props.onChange(value)}
                      name={inputName}
                      error={errors[inputName] && errors[inputName].message}
                      when={
                        input.when &&
                        Object.keys(input.when).reduce(
                          (acc, whenKey, ynridx) => {
                            acc[whenKey] = input.when[whenKey].map((row) => (
                              <FormRow
                                inputs={row}
                                namePrefix={prefix}
                                idx={`${idx}-${iidx}-${whenKey}`}
                                key={`${idx}-${iidx}-${whenKey}`}
                                ridx={ynridx}
                                form={form}
                              />
                            ));
                            return acc;
                          },
                          {},
                        )
                      }
                    />
                  )}
                  name={inputName}
                  control={control}
                />
              </>
            )}

            {input.type === "textarea" && (
              <TextArea
                {...input}
                key={`section-${idx}-input`}
                ref={register(input.options)}
                type={input.type || "text"}
                name={inputName}
                label={input.label}
                placeholder={input.placeholder}
                helpText={input.helpText}
                value={form.watch(inputName)}
                error={errors[inputName] && errors[inputName].message}
                required={!!input.options && !!input.options.required}
              />
            )}
            {input.type === "file" && (
              <Controller
                rules={input.options}
                render={(props) => {
                  return (
                    <Files
                      className="files-dropzone"
                      {...input}
                      {...props}
                      value={form.watch(inputName)}
                      name={inputName}
                      error={errors[inputName] && errors[inputName].message}
                    />
                  );
                }}
                name={input.name}
                control={control}
              />
            )}
            {input.type === "collapsable" && (
              <Collapsable
                input={input}
                idx={`section-${idx}-${iidx}-collapsable`}
                expanded={input.expanded}
                collapsed={input.collapsed}
                errors={errors}
                name={prefix}
                form={form}
              />
            )}
            {input.type === "date" && (
              <Date
                key={`section-${idx}-${iidx}-date`}
                name={inputName}
                label={input.label}
                error={errors[inputName] && errors[inputName].message}
                helpText={input.helpText}
                required={!!input.options && !!input.options.required}
                form={form}
                options={input.options}
              />
            )}
            {input.type === "custom" && (
              <input.Component
                input={input}
                error={errors[inputName] && errors[inputName].message}
                form={form}
                key={`section-${idx}-${iidx}-custom`}
                idx={`section-${idx}-${iidx}-custom`}
                prefix={prefix}
                setCurrentStepData={setCurrentStepData}
              />
            )}
            {input.type === "entite-select" && (
              <EntiteSelect
                input={input}
                form={form}
                key={`section-${idx}-${iidx}-entite-select`}
                idx={`section-${idx}-${iidx}-entite-select`}
                prefix={prefix}
                setCurrentStepData={setCurrentStepData}
                When={
                  input.when[form.watch(inputName)] &&
                  input.when[form.watch(inputName)].map((row, ridx) => (
                    <FormRow
                      key={`${idx}-sel-when-${iidx}-${ridx}`}
                      idx={`${idx}-sel-when-${iidx}-${ridx}`}
                      namePrefix={prefix}
                      inputs={row}
                      form={form}
                      errors={form.errors}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))
                }
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
