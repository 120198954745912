import React, { useRef, useEffect } from "react";

import { Controller } from "react-hook-form";

export default ({ content, name, className, disabled, condition }) => {
  let classes = className || "italic text-sm";

  if (disabled) {
    classes += " opacity-50";
  }

  return (
    <span
      id={name}
      className={classes}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
