import React from "react";
import { useUser } from "stores/user";

export default function Rating({
  className,
  name,
  label,
  required,
  error,
  helpText,
  idx,
  choices,
  form,
}) {
  let classes = "rating-input " + (className || "");
  const { candidature } = useUser(({ candidature }) => ({ candidature }));

  if (required) {
    classes += " required";
  }

  const displayableChoices = choices.filter((choice) => {
    if (choice.condition && typeof choice.condition === "function") {
      return choice.condition(name, candidature);
    }

    return true;
  });

  return (
    <div className={classes} htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <div className="flex  justify-center w-full">
        {displayableChoices.map((choice, cidx) => (
          <label
            className="rating-radio"
            htmlFor={`rating-choice-${name}.${choice.value}`}
            key={`${idx}-${cidx}`}
          >
            <input
              id={`rating-choice-${name}.${choice.value}`}
              ref={form.register()}
              type="radio"
              name={name}
              value={choice.value}
            />
            <span>{choice.label}</span>
          </label>
        ))}
      </div>
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}
    </div>
  );
}
